import React from "react"
import Layout from "../components/layout";

export default () => (
    <Layout>
      <h1>Alex Blogs</h1>
      <p>
        Welcome to my blog! I will be migrating content from old blog soon.
      </p>
    </Layout>
  )
